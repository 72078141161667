import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutNav from "../components/about-nav"

const TaiChiInfoPage = () => {
  const data = useStaticQuery(graphql`
    query InfoPageQuery {
      site {
        siteMetadata {
          title
          pageTitles {
            info
          }
        }
      }
    }
  `)
  const { title: siteTitle, pageTitles } = data.site.siteMetadata

  return (
    <Layout meta="info">
      <Seo title={`${siteTitle} | ${pageTitles.info}`} />
      <section className="textual">
        <div className="wrapper">
          <h1>{pageTitles.info}</h1>
          <div className="block twothirdcol">
            <div className="block col1">
              <p>
                One day the Daoist monk Chang San-feng was disturbed by the
                sounds of a snake and a crane fighting in his courtyard. Each
                time the crane’s rapierlike beak stabbed, the flexible snake
                twisted out of reach. And the crane’s wings, like shields,
                protected its long neck from the snake’s striking head.
                According to the myth, from observing this battle, Chang
                San-feng developed the art of Tai-Chi Chuan (Grand Ultimate or
                Supreme Fist), which is based on the concept of yielding in the
                face of aggression.
              </p>
              <p>
                The study of Tai-Chi Chuan is unique in the sense that it marks
                the historical meeting of many centuries of Daoist study known
                as Chi Kung ("Excellence of Energy"), which was primarily
                dedicated to physical health and spiritual growth, with the need
                at the time (approximately 1,000 A.D.) for monks to defend
                themselves against bandits and warlords. The result was an
                unusual blend of healing art/martial art/meditation which has
                been referred to as the internal practice of Tai-Chi Chuan.
              </p>
              <p>
                In each of these expressions, this emphasis on the internal
                aspect of the study is primary. This indicates that the true
                focus of the study is not solely, nor even primarily, on the
                physical level, but places the emphasis of the practice more on
                the mental and energetic levels. The mental component is really
                most important since the number one condition that inhibits an
                individual from achieving excellence in anything, including
                one’s own health, is a state that Traditional Chinese Medicine
                refers to as being "weak-minded". This "weak-minded" state
                indicates one who is easily confused or distracted. So the first
                quality to be developed in Tai-Chi is that of strengthening
                one’s concentration, or what is referred to in the martial arts
                as being centered.
              </p>
              <p>
                The ability to center the mind is really that of keeping the
                mind interested and involved in the experience of the present
                moment. This is understood to be the foundation of Tai-Chi
                because from this state of attention comes the possibility to
                change, correct, and heal. To facilitate this process Tai-Chi
                uses a physical location, the lower abdomen/pelvis, which is
                called the Dan Tien in Chinese. This represents the true body
                center in the sense that it’s the natural movement and feeling
                center. With this specific body awareness we can begin the
                process of distributing the attention more evenly and equally
                throughout the body. The ability to spread attention throughout
                the body is understood in Chinese medicine to be one of the most
                important elements of good health because it’s indicative of the
                relationship between the mind and the body. Unlike the
                prevailing Western view that one must work hard for the
                experience we call being healthy, in Tai Chi health is
                understood to be natural (and therefore effortless) to that
                individual who has achieved balance and harmony between body and
                mind.
              </p>
              <h2>Change and Discovery</h2>
              <p>
                Tai-Chi has, during it’s 1000 years of development, been
                considered to be a movement art. This implies much more than
                just physical or even energetic movement. It denotes a
                relationship with the experience of change. The attitude which
                Tai-Chi seeks to cultivate is an understanding of change as a
                natural life process. One is asked to look at tendencies in
                which we resist change, possibly even fear it. Through the
                practice of Tai-Chi one allows oneself to become a more willing
                participant in the process of change, understanding that it is
                inevitable anyway. The practice begins primarily as a physical
                experience, but given time, applies the qualities that we seek
                to develop physically, including balance, good timing, and
                integration, to the emotional, mental, and spiritual levels as
                well.
              </p>
              <p>
                The essence of Tai-Chi practice is not to learn a set of
                movements, nor to become talented in a system of self-defense,
                although these abilities may occur during the course of
                practice. The intention of Tai-Chi is to allow one the
                opportunity to become more aware of the natural laws which
                govern change; not just change in the body as affects physical,
                structural movement, but rather principles of change and
                movement that govern every aspect of our lives and the world
                around us. The exercises of the practice simply provide us with
                an opportunity to explore that process of discovery.
              </p>
            </div>
            <div className="block col2">
              <AboutNav current="info" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TaiChiInfoPage
